@import '~antd/dist/antd.css';
@import '~ag-grid-community/dist/styles/ag-grid.css';
@import '~ag-grid-community/dist/styles/ag-theme-alpine.css';
@import '~ag-grid-community/dist/styles/ag-theme-material.css';
@import '~react-grid-layout/css/styles.css';
@import '~react-resizable/css/styles.css';
@import 'fonts/sfhello.css';

main.ant-layout-content {
    position: relative;
}

.ant-btn .anticon>svg{
    width: attr(size em, 1) !important;
    height: attr(size em, 1) !important;
}

.ant-space.align-right{
    width: 100%;
    justify-content: right;
}

.ant-select-dropdown.hide-disabled-checkbox .ant-select-tree-checkbox-disabled{
    display: none;
}

/* fix drag&drop table rows in modal */
body > tr {
    display: table !important;
}

/**
 * PRINTING DEFAULTS
 */
@media all {
    .page-break {
        display: none;
    }
}
@media print {
    html, body {
        height: initial !important;
        overflow: initial !important;
        -webkit-print-color-adjust: exact;
        background-color: #f0f2f5;
    }
}
@media print {
    .page-break {
        margin-top: 1rem;
        display: block;
        page-break-before: auto;
    }
    .ant-layout-header, .ant-breadcrumb{
        display: none;
    }
    .ant-layout-content, html, body, #root{
        margin: 0 !important;
        padding: 0 !important;
    }
}
@page {
    /*size: auto;*/
    /*margin: 20mm;*/
}