@import url(https://fonts.googleapis.com/css?family=Roboto|Montserrat);

:root {
  --pageViewHeaderHeight: 100px;
}

$backgroundColor: #F4F4F4;
$inputBackgroundColor: #EFF1F7;
$pageViewHeaderHeight: var(--pageViewHeaderHeight);


//External mobile modal
.npi-external-mobile-modal-full-screen {
  max-width: unset;
  width: 100% !important;
  top: 0;
  padding-bottom: 0;
  margin: 0;

  .ant-modal-content {
    height: 100vh;
    border-radius: 0;

    .ant-modal-body {
      height: 90%;
    }

    .ant-modal-footer {
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }


  #success-survey-modal-body {

    text-align: center;

    h1 {
      margin-top: 20vh;
      margin-bottom: 50px;
    }

    .list-after-submit-links {
      max-width: 400px;
      margin-left: auto;
      margin-right: auto;
      button {
        height: 40px;
        margin-bottom: 10px;
      }
    }

  }
}


.npi-external-app {
  background-color: $backgroundColor;
  font-family: "Montserrat", sans-serif;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: hidden;

  //antd header
  .ant-page-header {
    min-height: $pageViewHeaderHeight;
    background-color: white;
    margin-bottom: 2px;

    .ant-page-header-content {
      padding-top: 0;
    }
  }

  .ant-empty {
    .ant-empty-image {
      height: auto;

      .anticon {
        color: gray;
      }
    }
  }

  .ant-tabs-nav {
    margin-bottom: 5px !important;
  }

  .ant-tabs-nav-list {
    background-color: white;
    width: 100%;
    padding-left: 5px;
    padding-right: 5px;
  }

  a, .link {
    cursor: pointer;
    color: #6567AF;
    font-family: "Roboto", sans-serif;

    &:hover {
      color: #6d6fbb;
    }
  }

  .ant-input {
    border-radius: 5px;
    background-color: $inputBackgroundColor;

    &:focus, &:hover {
      border-color: $inputBackgroundColor;
    }
  }

  #pos-view {
    #pos-view-header {
      .ant-page-header {
        padding: 10px 12px;
      }
    }

    .ant-card-body {
      .ant-picker-rtl{
        float: left !important;
      }
    }

    .ant-tabs {

      .ant-tabs-tab .anticon {
        margin: 0;
      }

      .ant-tabs-tab-btn {
        display: block;
        margin: auto;
      }



      .wave-collapse-panel {
        border-top: unset;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        margin-bottom: 10px;

        .ant-collapse-header {
          z-index: 100;
          width: 100%;
          position: sticky;
          top: 0px;
          background-color: #e3e3e3;
          border-bottom: 1px solid #d9d9d9;
          border-top: 1px solid #d9d9d9;
        }

        .ant-collapse-content {
          border-top: unset;
          background-color: $backgroundColor;

          .ant-collapse-content-box {
            padding: 5px;
          }
        }
      }
    }

    #surveys-errors {
      padding: 10px;
      margin-top: 15px;
      margin-bottom: 100px;
      background-color: #fcf8e3 !important;

      .ant-tree, .ant-tree-node-content-wrapper:hover {
        background-color: #fcf8e3 !important;
      }
    }

    .ant-tabs-top > .ant-tabs-nav {
      margin: 0;
    }

    .ant-card-head-title {
      font-size: 0.9em;
      overflow: unset;
      white-space: unset;
      text-overflow: unset;
    }

    #tab-images-gallery {

      .images-gallery-content {

        .gallery-image {
          min-height: 50px;
          position: relative;
          border: 1px solid rgba(128, 128, 128, 0.24);
          border-radius: 2px;


          .edit-btn {
            position: absolute;
            bottom: 0px;
            left: 0;
          }

          img, .ant-upload-picture-card-wrapper {
            width: 100%;
            height: 100%;
            padding: 3px;
          }
        }

        .ant-upload.ant-upload-select-picture-card > .ant-upload {
          display: block;
          margin-top: 18px;
        }


        .ant-upload-picture-card-wrapper {
          text-align: center;
        }
      }
    }
  }
}