@charset "utf-8";

@font-face {
    font-family: 'SF Pro Display';
    font-style: normal;
    font-weight: 500;
    src: local('☺︎'), url("sfprodisplay-medium.woff") format("woff"), url("sfprodisplay-medium.ttf") format("truetype");
}
@font-face {
    font-family: 'SF Pro Display';
    font-style: normal;
    font-weight: 200;
    src: local('☺︎'), url("sfprodisplay-light.woff") format("woff"), url("sfprodisplay-light.ttf") format("truetype");
}
@font-face {
    font-family: 'SF Pro Text';
    font-style: normal;
    font-weight: 400;
    src: local('☺︎'), url("sfprotext-regular.woff") format("woff"), url("sfprotext-regular.ttf") format("truetype");
}
@font-face {
    font-family: 'SF Pro Text';
    font-style: normal;
    font-weight: 600;
    src: local('☺︎'), url("sfprotext-medium.woff") format("woff"), url("sfprotext-medium.ttf") format("truetype");
}
@font-face {
    font-family: 'SF Pro Text';
    font-style: italic;
    font-weight: 400;
    src: local('☺︎'), url("sfprotext-regularitalic.woff") format("woff"), url("sfprotext-regularitalic.ttf") format("truetype");
}
@font-face {
    font-family: 'SF Pro Text';
    font-style: normal;
    font-weight: 200;
    src: local('☺︎'), url("sfprotext-light.woff") format("woff"), url("sfprotext-light.ttf") format("truetype");
}
@font-face {
    font-family: 'SF Pro Text';
    font-style: italic;
    font-weight: 200;
    src: local('☺︎'), url("sfprotext-lightitalic.woff") format("woff"), url("sfprotext-lightitalic.ttf") format("truetype");
}
@font-face {
    font-family: 'SF Hello';
    font-style: normal;
    font-weight: 400;
    src: local('☺︎'), url("sfhello-regular.woff") format("woff"), url("sfhello-regular.ttf") format("truetype");
}
@font-face {
    font-family: 'SF Hello';
    font-style: normal;
    font-weight: 200;
    src: local('☺︎'), url("sfhello-light.woff") format("woff"), url("sfhello-light.ttf") format("truetype");
}
@font-face {
    font-family: 'SF Hello';
    font-style: normal;
    font-weight: 500;
    src: local('☺︎'), url("sfhello-medium.woff") format("woff"), url("sfhello-medium.ttf") format("truetype");
}
@font-face {
    font-family: 'SF Hello';
    font-style: normal;
    font-weight: 600;
    src: local('☺︎'), url("sfhello-semibold.woff") format("woff"), url("sfhello-semibold.ttf") format("truetype");
}
@font-face {
    font-family: 'SF Hello';
    font-style: normal;
    font-weight: 700;
    src: local('☺︎'), url("sfhello-bold.woff") format("woff"), url("sfhello-bold.ttf") format("truetype");
}
@font-face {
    font-family: 'SF Hello';
    font-style: normal;
    font-weight: 100;
    src: local('☺︎'), url("sfhello-thin.woff") format("woff"), url("sfhello-thin.ttf") format("truetype");
}
